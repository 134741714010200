import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Styles
import './assets/css/style.css';

import OneSignalVue from "onesignal-vue";
Vue.use(OneSignalVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: "fac6072e-587a-46f1-b319-48479b554625" });
  },
}).$mount("#app");
