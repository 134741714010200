<template>
  <div class="page container">
    <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1>
    <p> Quibusdam suscipit odit ut exercitationem doloremque laborum, quos sunt quidem totam nemo, aliquam veritatis distinctio, minima saepe itaque modi ea! Iusto, aliquid?</p>
    <div class="buttons">
      <button id="install" @click="install">Установить</button>
      <button id="subscribe" @click="subscribe">Подписаться на новости</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      deferredPrompt: null
    }
  },
  beforeCreate(){
    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
    });
  },
  methods:{
    async install(){
      this.deferredPrompt.prompt();
    },
    async subscribe(){
      this.$OneSignal.showNativePrompt();
    }
  }
}
</script>
