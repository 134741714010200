<template>
  <div id="app">
    <Header/>
    <main id="content" :class="this.$route.meta.class">
      <router-view/>
    </main>
    <Footer/>
  </div>
</template>
<script>
import Header from './components/parts/Header.vue';
import Footer from './components/parts/Footer.vue';
export default {
  components:{
    Header, Footer
  },
  data() {
    return {
      c: localStorage.getItem('c').split('_') || null,
      urlParams: '',
    }
  },
  created(){
    this.launch()
  },
  mounted(){
    this.urlParams = new URLSearchParams(window.location.search);
    if(this.urlParams.get('c')){
      localStorage.setItem('c', this.urlParams.get('c'));
      this.c = this.urlParams.get('c').split('_');
    }
  },
  methods: {
    launch(){
      if(window.matchMedia("(display-mode: fullscreen)").matches || window.matchMedia("(display-mode: standalone)").matches){
        this.$OneSignal.showNativePrompt();
        this.$OneSignal.isPushNotificationsEnabled((isEnabled)=>{
          if(isEnabled){
            this.$OneSignal.sendTags({
              type: this.c[0] ? this.c[0] : '',
              buyer_id: this.c[1] ? this.c[1] : '',
              offer: this.c[2] ? this.c[2] : '',
              geo: this.c[4] ? this.c[4].toLowerCase() : '',
              device: this.c[5] ? this.c[5].toLowerCase() : ''
            });
          }
        });
      }
    }
  },
}
</script>
